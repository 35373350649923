import Vue from "vue";
import Router from "vue-router";
const je = require("json-encrypt");

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

// Views - Components

const Forms = () => import("@/views/base/Forms");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Register = () => import("@/views/pages/Register");

const userGuard = (to, from, next) => {
  let user = window.localStorage.getItem("user");
  let user_permissions = window.localStorage.getItem("user_permissions");

  if (user == null || user == undefined) {
    next("/");
  } else {
    user = JSON.parse(JSON.parse(je.decrypt(user)));
    if (user.api_token.length != 60) {
      next("/");
    } else {
      let isAuthenticated = false;
      if (user_permissions == null || user_permissions == undefined) {
        next({ name: "Page404" });
      } else {
        user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
        if (user_permissions.indexOf(to.name) > -1) isAuthenticated = true;
      }
      if (!isAuthenticated) next({ name: "Page404" });
      else next();
    }
  }
};

Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/",
      name: "Login",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "/",
          name: "LoginPage",
          component: Login,
        },
      ],
    },

    {
      path: "/inicio",
      redirect: "/inicio/inicio",
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "inicio",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
      ],
    },


    {
      path: "/usuario",
      redirect: "/usuario/listar",
      name: "Usuario",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "UserList",
          component: () => import("@/views/user/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "UserAdd",
          component: () => import("@/views/user/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_user",
          name: "UserEdit",
          component: () => import("@/views/user/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_user",
          name: "UserView",
          component: () => import("@/views/user/View"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "profile",
          name: "UserProfile",
          component: () => import("@/views/pages/Profile"),
          props: true,
        },
      ],
    },

    {
      path: "/tipo-usuario",
      redirect: "/tipo-usuario/listar",
      name: "TipoUsuario",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "UserTypeList",
          component: () => import("@/views/usertype/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "UserTypeAdd",
          component: () => import("@/views/usertype/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_user_type",
          name: "UserTypeEdit",
          component: () => import("@/views/usertype/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_user_type",
          name: "UserTypeView",
          component: () => import("@/views/usertype/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/caja",
      redirect: "/caja/listar",
      name: "Box",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "BoxList",
          component: () => import("@/views/box/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "BoxAdd",
          component: () => import("@/views/box/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_box",
          name: "BoxEdit",
          component: () => import("@/views/box/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_box",
          name: "BoxView",
          component: () => import("@/views/box/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/ingreso-administrativo",
      redirect: "/ingreso-administrativo/listar",
      name: "Income",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "IncomeList",
          component: () => import("@/views/income/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "IncomeAdd",
          component: () => import("@/views/income/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_income",
          name: "IncomeEdit",
          component: () => import("@/views/income/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_income",
          name: "IncomeView",
          component: () => import("@/views/income/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/egreso-administrativo",
      redirect: "/egreso-administrativo/listar",
      name: "Expense",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ExpenseList",
          component: () => import("@/views/expense/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ExpenseAdd",
          component: () => import("@/views/expense/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_expense",
          name: "ExpenseEdit",
          component: () => import("@/views/expense/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_expense",
          name: "ExpenseView",
          component: () => import("@/views/expense/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/empresa",
      redirect: "/empresa/editar",
      name: "Eempresa",
      component: TheContainer,
      children: [
        {
          path: "editar",
          name: "BusinessEdit",
          component: () => import("@/views/business/Edit"),
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/comprobante",
      redirect: "/comprobante/listar",
      name: "Voucher",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "VoucherList",
          component: () => import("@/views/voucher/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "VoucherAdd",
          component: () => import("@/views/voucher/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_voucher",
          name: "VoucherEdit",
          component: () => import("@/views/voucher/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_voucher",
          name: "VoucherView",
          component: () => import("@/views/voucher/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/dato-financiero",
      redirect: "/dato-financiero/listar",
      name: "FinancialData",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "FinancialDataList",
          component: () => import("@/views/financial-data/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "FinancialDataAdd",
          component: () => import("@/views/financial-data/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_financial_data",
          name: "FinancialDataEdit",
          component: () => import("@/views/financial-data/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_financial_data",
          name: "FinancialDataView",
          component: () => import("@/views/financial-data/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/sucursal",
      redirect: "/branch-office/listar",
      name: "BranchOffice",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "BranchOfficeList",
          component: () => import("@/views/branch-office/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "BranchOfficeAdd",
          component: () => import("@/views/branch-office/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_branch_office",
          name: "BranchOfficeEdit",
          component: () => import("@/views/branch-office/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_branch_office",
          name: "BranchOfficeView",
          component: () => import("@/views/branch-office/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/cliente",
      redirect: "/cliente/listar",
      name: "Client",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ClientList",
          component: () => import("@/views/client/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ClientAdd",
          component: () => import("@/views/client/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_client",
          name: "ClientEdit",
          component: () => import("@/views/client/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_client",
          name: "ClientView",
          component: () => import("@/views/client/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/garante",
      redirect: "/garante/listar",
      name: "Guarantor",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "GuarantorList",
          component: () => import("@/views/guarantor/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "GuarantorAdd",
          component: () => import("@/views/guarantor/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_guarantor",
          name: "GuarantorEdit",
          component: () => import("@/views/guarantor/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_guarantor",
          name: "GuarantorView",
          component: () => import("@/views/guarantor/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/requisito",
      redirect: "/requisito/listar",
      name: "Requirement",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "RequirementList",
          component: () => import("@/views/requirement/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "RequirementAdd",
          component: () => import("@/views/requirement/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_requirement",
          name: "RequirementEdit",
          component: () => import("@/views/requirement/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_requirement",
          name: "RequirementView",
          component: () => import("@/views/requirement/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/simulador",
      redirect: "/simulador/listar",
      name: "Simulation",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "SimulationList",
          component: () => import("@/views/simulation/List"),
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/credito",
      redirect: "/credito/listar",
      name: "Credit",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "CreditList",
          component: () => import("@/views/credit/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "CreditAdd",
          component: () => import("@/views/credit/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_credit",
          name: "CreditEdit",
          component: () => import("@/views/credit/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_credit",
          name: "CreditView",
          component: () => import("@/views/credit/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/amortizacion-cuota",
      redirect: "/amortizacion-cuota/listar",
      name: "InstallmentAmortization",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "InstallmentAmortizationList",
          component: () => import("@/views/credit-installment-amortization/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "InstallmentAmortizationAdd",
          component: () => import("@/views/credit-installment-amortization/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_installment_amortization",
          name: "InstallmentAmortizationEdit",
          component: () => import("@/views/credit-installment-amortization/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_installment_amortization",
          name: "InstallmentAmortizationView",
          component: () => import("@/views/credit-installment-amortization/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/amortizacion-credito",
      redirect: "/amortizacion-credito/listar",
      name: "CreditAmortization",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "CreditAmortizationList",
          component: () => import("@/views/credit-amortization/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "CreditAmortizationAdd",
          component: () => import("@/views/credit-amortization/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_credit_amortization",
          name: "CreditAmortizationEdit",
          component: () => import("@/views/credit-amortization/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_credit_amortization",
          name: "CreditAmortizationView",
          component: () => import("@/views/credit-amortization/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/refinanciar-credito",
      redirect: "/refinanciar-credito/listar",
      name: "CreditRefinance",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "CreditRefinanceList",
          component: () => import("@/views/credit-refinance/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "CreditRefinanceAdd",
          component: () => import("@/views/credit-refinance/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_credit_refinance",
          name: "CreditRefinanceEdit",
          component: () => import("@/views/credit-refinance/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_credit_refinance",
          name: "CreditRefinanceView",
          component: () => import("@/views/credit-refinance/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/reduccion-cuotas",
      redirect: "/reduccion-cuotas/listar",
      name: "CreditFeeReduction",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "CreditFeeReductionList",
          component: () => import("@/views/credit-fee-reduction/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "CreditFeeReductionAdd",
          component: () => import("@/views/credit-fee-reduction/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_credit_fee_reduction",
          name: "CreditFeeReductionEdit",
          component: () => import("@/views/credit-fee-reduction/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_credit_fee_reduction",
          name: "CreditFeeReductionView",
          component: () => import("@/views/credit-fee-reduction/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },



    {
      path: "/desembolso",
      redirect: "/desembolso/listar",
      name: "Disbursement",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "DisbursementList",
          component: () => import("@/views/disbursement/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "DisbursementAdd",
          component: () => import("@/views/disbursement/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_disbursement",
          name: "DisbursementEdit",
          component: () => import("@/views/disbursement/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_disbursement",
          name: "DisbursementView",
          component: () => import("@/views/disbursement/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/pago-credito",
      redirect: "/pago-credito/listar",
      name: "CreditPayment",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "CreditPaymentList",
          component: () => import("@/views/credit-payment/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "CreditPaymentAdd",
          component: () => import("@/views/credit-payment/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_credit_payment",
          name: "CreditPaymentEdit",
          component: () => import("@/views/credit-payment/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_credit_payment",
          name: "CreditPaymentView",
          component: () => import("@/views/credit-payment/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/reporte",
      redirect: "/reporte/saldo-usuario",
      name: "Report",
      component: TheContainer,
      children: [
        {
          path: "saldo-usuario",
          name: "ReportUserBalanceList",
          component: () => import("@/views/report/UserBalance"),
          beforeEnter: userGuard,
        },
        {
          path: "saldo-morosos",
          name: "ReportBalanceBadDebtsList",
          component: () => import("@/views/report/BalanceBadDebts"),
          beforeEnter: userGuard,
        },
        {
          path: "desembolso-creditos",
          name: "ReportCreditDisbursementList",
          component: () => import("@/views/report/CreditDisbursement"),
          beforeEnter: userGuard,
        },
        {
          path: "ingresos-del-dia",
          name: "ReportIncomeOfTheDayList",
          component: () => import("@/views/report/IncomeOfTheDay"),
          beforeEnter: userGuard,
        },
        {
          path: "resumen-de-saldo-morosos",
          name: "ReportDelinquentBalanceSummaryList",
          component: () => import("@/views/report/DelinquentBalanceSummary"),
          beforeEnter: userGuard,
        },
        {
          path: "general-de-cobranza",
          name: "ReportCollectionGeneralList",
          component: () => import("@/views/report/CollectionGeneral"),
          beforeEnter: userGuard,
        },
        {
          path: "ingresos-mes-mype",
          name: "ReportMypeMonthIncomeDetailsList",
          component: () => import("@/views/report/MypeMonthIncomeDetails"),
          beforeEnter: userGuard,
        },
        {
          path: "ingresos-mes-agropecuario-personal",
          name: "ReportDetailsOfAgriculturalMonthIncomePersonalList",
          component: () => import("@/views/report/DetailsOfAgriculturalMonthIncomePersonal"),
          beforeEnter: userGuard,
        },
        {
          path: "ventas",
          name: "ReportSalesList",
          component: () => import("@/views/report/Sales"),
          beforeEnter: userGuard,
        },
        {
          path: "ingreso-mensual",
          name: "ReportMonthlyIncomeList",
          component: () => import("@/views/report/MonthlyIncome"),
          beforeEnter: userGuard,
        },
        {
          path: "creditos-por-vencerse",
          name: "ReportCreditsToExpireList",
          component: () => import("@/views/report/CreditsToExpire"),
          beforeEnter: userGuard,
        },
        {
          path: "clientes",
          name: "ReportClientsList",
          component: () => import("@/views/report/Clients"),
          beforeEnter: userGuard,
        },
        {
          path: "historial-credito",
          name: "ReportCreditHistoryList",
          component: () => import("@/views/report/CreditHistory"),
          beforeEnter: userGuard,
        },
      ],
    },

  


  ];
}
